///
/// @file global/_variables.scss
///
/// \brief Variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do not directly result in rendered CSS.
/// @setup change all for new brand

// Layout
$max-width: 1280px !default;
$header-height: 60px !default; // used to offset anchors, etc.

// Font / Typography related variables

// International
$rdirection: right !default;
$ldirection: left !default;
$text-direction: ltr !default;

// Font Sizes
$font--small: 75%;
$font--medium: 100%;
$font--large: 125%;
$extra-extra-large-up: 1366px;

// Font Family
$font--serif: serif !default;
$font--sans: sans-serif !default;
$font--mono: monospace !default;

$primary-font: $font--sans !default;
$primary-font-rev: $primary-font;
$primary-font-eng: $primary-font;
$font-meta: $primary-font;
$font-meta-normal: $primary-font;

// Borders
$border-gray: $color-gray;
$border-light-gray: $color-light-gray;
$border-black: $black;

$border: $border-gray solid 1px;
$border--light: $border-light-gray solid 1px;
$border--color-light-gray: $light-gray solid 1px;
$border-black: $border-black 1px solid ;
$border-form: 1px solid $border-black;
$border-thick-width: 6px;
