@import '../../../../scss/theme-bootstrap';

.breadcrumbs {
  max-width: none;
  background-color: $color-primary-500;
  padding: 10px 20px;
  @include breakpoint($medium-up) {
    padding: 10px 30px;
  }
  &__level {
    font-size: 13px;
    color: $color-black;
    text-decoration: none;
    &:before {
      content: ' /';
    }
    &:first-child:before {
      content: '';
    }
    &:last-child:before {
      content: '';
    }
    a {
      font-size: 13px;
      color: $color-black;
      text-decoration: none;
    }
  }
}

// Hide separator between breadcrumbs items.
nav.breadcrumbs {
  .without-separator {
    &:before {
      content: '';
    }
  }
}

.breadcrumb__level--count {
  float: $rdirection;
  min-width: 86px; // iOS style fix
}
